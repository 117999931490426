.fondoLogin.container {
    background: url(http://api.pbgdev.com.ar/public/1597856763-177.jpg);
    background-size: cover;
}

/* fondo de pantalla en vista de Login */
.cardLogin{
    transition: all .3s;
    margin-top: 3rem;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 100px rgba(0, 0, 0, .6);
    border-radius: 15px;
    z-index: 1;
}


.cardLogin label{
    color: white;
    font-size: 1.2rem;
}

.fondoLogin{
    min-width: 100vw;
    min-height: 100vh;
    background-image: url(http://api.pbgdev.com.ar/public/1597856763-177.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.logo{
    background-image: url(http://www.pbgdesarrollos.com.ar/images/imagotipo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: saturate(150%);
    margin: auto;
    width: 100%;
    min-height: 10vw;
    margin-bottom: 1rem;
}

.falloLogin{
    background-color: tomato;
    margin: 3rem auto 1rem auto;
    padding: 0 .5rem;
    border-radius:15px;
    color: white;
    text-align: center;

}

.falloLogin span{
    font-size: 2rem;
}

@media (max-width: 1023px) { /* Ex 768 (Display de una ipad)*/
    .cardLogin{
        margin-top:25%;
    }
    .logo{
        height: 180px;
    }
    .TileConteiner article{
        margin-right: -8.5rem;
        transform: perspective(700px) rotateY(55deg);
    }
    @keyframes apilarse {
        0%{
            margin-right: 0;
            transform: perspective(0) rotateY(0);
        }
        100% {
            margin-right: -8.5rem;
            transform: perspective(700px) rotateY(55deg);
        }
    }
    
}

.cajaLogin{

background: white;
padding: 20px;
margin-top: 200px;
}