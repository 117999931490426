.buttonForm {
    float: right;
}
.buttonView {
    margin-top: -15px;
    float: right;
}
.buttonBack {
    margin-top: -15px;
    float: left;
}